import { useEffect } from "react";
import DataService from "services/DataService";

export const useDOMTitle = ({ project }) => {
  useEffect(() => {
    if (project) {
      document.title = document.title =
        DataService.capitalize(project) + " - Status";
    }
  }, [project]);
};
