import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

import Appsignal from "@appsignal/javascript";
import { ErrorBoundary } from "@appsignal/react";

import TagManager from 'react-gtm-module';

import { OnlineProvider } from 'context/OnlineContext';
import { projects_idGa } from 'views/utilities/parameters';

// ==============================|| APP ||============================== //

if(projects_idGa) {
    TagManager.initialize({ gtmId: projects_idGa });
    console.log('[GA]: Google Analytics connected')
  }

const appsignal = new Appsignal({
    key: window.env.GENERIC_FRONTEND_REACTJS_STATUS_APPSIGNAL_MONITORING_KEY
})

const FallbackComponent = (error) => (
  <div>{"AppSignal: There was an error: " + error}</div>
)

const App = () => {
    const customization = useSelector((state) => state.customization);
    
    return (
        <ErrorBoundary instance={appsignal} fallback={(event) => <FallbackComponent error={event} />}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <OnlineProvider>
                          <Routes />
                        </OnlineProvider>
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
        </ErrorBoundary>
    );
};

export default App;
