// material-ui

// project imports
import LogoSection from "../LogoSection";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  return (
      <LogoSection />
  );
};

export default Header;
