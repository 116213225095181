import {Component} from 'react'
//------------------------------------------------------------------------------------

import CookieService from "./CookieService";
import RoutingService from "./RoutingService";
//------------------------------------------------------------------------------------

class SecurityService extends Component {
    static acl = {
        login: { path: '/login', auth: false, namespace: 'login' },

        error500:   { path: '/error500', auth: false, namespace: 'error_pages' },
        error403:   { path: '/error403', auth: false, namespace: 'error_pages' },
        error404:   { path: '/error404', auth: false, namespace: 'error_pages' },

        home: { path: '/', auth: true, namespace: 'app' },
        event_logs: { path: '/logs', auth: true, permission_endpoint_id: 'event_logs', namespace: 'app' },
    };

    static protectByAcl(action) {
        let url = null;
        let acl = SecurityService.acl;

        for (let key in acl) {
            let path = acl[key].path

            if (path.indexOf(':id') >= 0) {
                //Tiene parámetros...
                path = path.replace('/:id', '');

                if (RoutingService.urlContains(path)) {
                    url = acl[key];
                    break;
                }                
            }
            else {
                if (RoutingService.urlContainsExactly(path)) {
                    url = acl[key];
                    break;
                } 
            }
        }

        if (!url) return RoutingService.redirectTo(acl.error404.path);
        if (!RoutingService.urlContains('error')) {
            if (url.auth && !SecurityService.userIsLogin()) {
                CookieService.setCookie('url_before_login', RoutingService.getCurrentUrl(), true);
                return RoutingService.redirectTo(acl.login.path);
            }

            if (!SecurityService.userIsGrantedTo(url.permission_endpoint_id)) {
                return RoutingService.redirectTo(acl.error403.path);
            }

            if (url.namespace === 'login' && SecurityService.userIsLogin()) {
                return RoutingService.redirectTo(acl.home.path);
            }
        }

        action();
    }

    static login(
        token, client, id, name, email, photo_url,
        expiration_date, expiration_time, 
        roles_en, roles_es, roles_pt, allowed_tools, 
        redirect_url, remember) {

        CookieService.setCookie('user_token', token, remember);
        CookieService.setCookie('user_client', client, remember);
        CookieService.setCookie('user_id', id, remember);
        CookieService.setCookie('user_name', name, remember);
        CookieService.setCookie('user_email', email, remember);
        CookieService.setCookie('user_photo_url', photo_url, remember);

        CookieService.setCookie('user_expiration_date', expiration_date, remember);
        CookieService.setCookie('user_expiration_time', expiration_time, remember);

        CookieService.setCookie('user_roles_en', roles_en, remember);
        CookieService.setCookie('user_roles_es', roles_es, remember);
        CookieService.setCookie('user_roles_pt', roles_pt, remember);

        CookieService.setCookie('user_allowed_tools', JSON.stringify(allowed_tools), remember);

        if (redirect_url) {
            return RoutingService.redirectTo(redirect_url);
        }

        RoutingService.redirectTo(SecurityService.acl.home.path);
    }

    static isAdmin() {
        return (SecurityService.hasRole('Admin'));
    }

    static updateProfile(name, email, token) {
        CookieService.setCookie('user_name', name, true);
        CookieService.setCookie('user_email', email, true);  
        CookieService.setCookie('user_token', token, true);          
    }

    static updatePhotoUrl(url) {
        CookieService.setCookie('user_photo_url', url, true);
    }

    static userIsLogin() {
        return (
            SecurityService.getUserName() !== null &&
            SecurityService.getUserName() !== 'null' &&
            SecurityService.getUserName() !== '' &&
            SecurityService.getUserName() !== undefined
        );
    }

    static getUserToken() {
        return CookieService.getCookie('user_token');
    }

    static getUserClient() {
        return CookieService.getCookie('user_client');
    }

    static getUserId() {
        return CookieService.getCookie('user_id');
    }

    static userHasExpirationDate() {
        let expiration_date = null;
        expiration_date = CookieService.getCookie('user_expiration_date');

        return (
            expiration_date != null &&
            expiration_date !== 'null' &&
            expiration_date !== '' &&
            expiration_date !== undefined
        );        
    }    

    static getUserName() {
        return CookieService.getCookie('user_name');
    }

    static isGhostUser() {
        let username = CookieService.getCookie('user_email');
        return username === window.env.SMARTER_ADMIN_GHOST_EMAIL
    }

    static getShortUserName() {
        let short_name = null;
        short_name = CookieService.getCookie('user_name');

        if (short_name) {
            return short_name.split(' ')[0]
        }

        return null;
    }

    static getUserEmail() {
        return CookieService.getCookie('user_email');
    }

    static getMainUserPhotoUrl() {
        return CookieService.getCookie('user_photo_url');
    }

    static getUserPhotoUrl() {
        return CookieService.getCookie('user_photo_url');
    }

    static hasRole(role) {
        let roles = CookieService.getCookie('user_roles_en');

        return (roles.includes(role));
    }

    static getUserRole() {
        let locale = CookieService.getLocale();

        if (locale === "es") return CookieService.getCookie('user_roles_es');
        if (locale === "pt") return CookieService.getCookie('user_roles_pt');

        return CookieService.getCookie('user_roles_en');
    }

    static getPasswordExpirationDate() {
        return CookieService.getCookie('user_expiration_date');
    }

    static getPasswordExpirationTime() {
        return CookieService.getCookie('user_expiration_time');
    }  

    static userHasBeenWelcome() {
        return CookieService.getCookie('user_welcome');
    }  

    static welcomeUser() {
        CookieService.setCookie('user_welcome', '1', true);
    }  

    static get_allowed_tools() {
        let tools = null;

        tools = CookieService.getCookie('user_allowed_tools');

        return JSON.parse(tools)
    }

    static removeUserExpirationDate() {
        CookieService.removeCookie('user_expiration_date');    
        
        RoutingService.redirectTo(SecurityService.acl.home.path);
    }

    static userIsGrantedTo(tool, action='read') {
        // If it has no this property assigned, means that it is a tool not registered on permissions...
        // So, is a free tool, e.g., dashboard...
        if (!tool) return true;

        let allowed_tools = SecurityService.get_allowed_tools();
        if (!allowed_tools) return false;

        let tools = tool.split('|');
        let is_granted = false;

        tools.forEach((x) => {
            if (action === 'read') {
                is_granted = is_granted || (x in allowed_tools)
            }
            else {
                is_granted = is_granted || (x in allowed_tools && allowed_tools[x][action] === true)
            }
        });

        return is_granted;
    }    

    static logout() {
        CookieService.removeCookie('first_load');
        CookieService.removeCookie('user_token');
        CookieService.removeCookie('user_client');
        CookieService.removeCookie('user_id');
        CookieService.removeCookie('user_name');
        CookieService.removeCookie('user_email');
        CookieService.removeCookie('user_photo_url');
        CookieService.removeCookie('user_expiration_date');
        CookieService.removeCookie('user_roles_en');
        CookieService.removeCookie('user_roles_es');
        CookieService.removeCookie('user_roles_pt');
        CookieService.removeCookie('user_allowed_tools');
        CookieService.removeCookie('user_welcome');

        RoutingService.redirectTo(SecurityService.acl.login.path);       
    }
}

export default SecurityService;
