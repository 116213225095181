let es = {
  translation: {
    'English': 'Inglés',
    'Spanish': 'Español',
    'Portuguese': 'Portugués',
    'Event logs': 'Bitácora de acciones',
    'Available service': 'Servicio disponible',
    'Unavailable service': 'Servicio no disponible',
    'Data could not be fetched': 'Los datos no pudieron ser cargados',
    'No response': 'Sin respuesta',
    "Go to": 'Ir a',
    "Offline": "Offline",
    "incident": "Incidente en:",
    "loading": "Comprobando estado de los sistemas...",
    "all_ok": "Todos los sistemas normal"
  }
}

export default es;
