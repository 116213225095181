import { useProjectLogo } from 'hooks/useProjectLogo';

const Logo = () => {
	const logo = useProjectLogo();

  return (
      <img className="logo" src={logo} alt="Berry" width="100" />
  );
};

export default Logo;
