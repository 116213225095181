let pt = {
  translation: {
    'English': 'Inglês',
    'Spanish': 'Espanhol',
    'Portuguese': 'Português',
    'Event logs': 'Registro de estoque',
    'Available service': 'Serviço disponível',
    'Unavailable service': 'Serviço não disponível',
    'Data could not be fetched': 'Os dados não puderam ser buscados',
    'No response': 'Sem resposta',
    "Go to": 'Ir para',
    "incident": "Incidente em:",
    "loading": "Verificando o status dos sistemas...",
    "all_ok": "Todos os sistemas normais"
  }
}

export default pt;
