import React from "react";
import { Outlet } from "react-router-dom";

// material-ui
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project imports
import Breadcrumbs from "ui-component/extended/Breadcrumbs";
import Header from "./Header";
import { LinkButton } from "ui-component/LinkButton";

// import Sidebar from './Sidebar';
import navigation from "menu-items";
import { SET_MENU } from "store/actions";

// assets
import { IconChevronRight } from "@tabler/icons";
import SecurityService from "services/SecurityService";
import DataService from "services/DataService";

// hooks
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHeaderBg } from "hooks/useHeaderBg";
import { useMainProjectUrl } from "hooks/useMainProjectUrl";
import { useProjectName } from "hooks/useProjectName";
import { useIsEco } from "hooks/useIsEco";
import { useOnlineContext } from "context/OnlineContext";

// styles
import { ButtonContainer, Main, EcoContainer } from "./styles";
import { useDOMTitle } from "hooks/useDOMTitle";
import { useNodeEnv } from 'hooks/useNodeEnv';
import { EnvFeedback } from './EnvFeedback';

const MainLayout = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isEco } = useIsEco();
  const headerBg = useHeaderBg();
  const mainProjectUrl = useMainProjectUrl();
  const { isOnline } = useOnlineContext();
  const project = useProjectName();
  const {env, isProd} = useNodeEnv();
  useDOMTitle({ project });

  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();

  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  return (
    <>
      <div id="offline" className={isOnline ? "online" : "offline"}>
        {!isOnline && t("Offline")}
      </div>
      {!isProd && (
        <EnvFeedback nodeEnv={DataService.capitalize(env)} />
      )}
      <Grid
        container
        style={{ background: `url(${headerBg}) no-repeat` }}
        className={SecurityService.isGhostUser() ? "ghost-content" : ""}
        margin={0}
        height="100vh"
      >
        <Grid
          item
          width="100%"
          position="relative"
          paddingTop="30px"
          paddingBottom="30px"
          display="grid"
        >
          {!!mainProjectUrl && (
            <ButtonContainer>
              <LinkButton
                style={{ color: theme.palette.primary.main }}
                href={mainProjectUrl}
                title={`${t("Go to")} ${DataService.capitalize(
                  project.toLowerCase()
                )}`}
              />
            </ButtonContainer>
          )}
          {isEco && (
            <EcoContainer>
              <span className="material-symbols-outlined green">
                temp_preferences_eco
              </span></EcoContainer>
          )}
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Grid>
        {/* main content */}
        <Main open={leftDrawerOpened}>
          {/* breadcrumb */}
          <Breadcrumbs
            separator={IconChevronRight}
            navigation={navigation}
            icon
            title
            rightAlign
          />
          <Outlet />
        </Main>
        {/*<Customization />*/}
      </Grid>
    </>
  );
};

export default MainLayout;
