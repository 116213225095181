let en = {
  translation: {
    'English': 'English',
    'Spanish': 'Spanish',
    'Portuguese': 'Portuguese',
    'Event logs': 'Event logs',
    'Available service': 'Available service',
    'Unavailable service': 'Unavailable service',
    'Data could not be fetched': 'Data could not be fetched',
    'No response': 'No response',
    "Go to": 'Go to',
    "Offline": "Offline",
    "incident": "Incident with:",
    "loading": "Checking systems status...",
    "all_ok": "All systems normal"
  }
}

export default en;